import React from 'react';
import PropTypes from 'prop-types';

import Session from '../../Session';
import InnerHtml from '../InnerHtml';

import { isArray} from '../../Utils'

/**
 * match status class from response status
 * @see #332
 * @see survey.h: struct next_questions { .. enum { STATUS_INFO, STATUS_WARN, STATUS_ERROR } status; ..}
 */
const getStatusClass = function(status) {
    switch (status) {
        case 1:
            return 'warning';
        case 2:
            return 'danger';
        default:
            return 'primary';
    }
};

const SurveyMessage = function({ status, message }) {
    if (!message) {
        return (null);
    }
    const cls = getStatusClass(status);

    return (
        <div className={ `list-group-item list-group-item-${cls} mb-2` }><InnerHtml htmlContent={ message } /></div>
    );
};

SurveyMessage.defaultProps = {
    message: '',
};

SurveyMessage.propTypes = {
    status: PropTypes.number.isRequired,
    message: PropTypes.string,
};

const SurveyMessages = function({ session }) {
    const { status, message, notes } = session;
    const _notes = isArray(notes) ? notes : [];

    return (
        <div className="ss-survey-messages list-group">
            { message && <SurveyMessage status={ status } message={ message } /> }
            { _notes.map((note, index) => <SurveyMessage key={ index } status={ 0 } message={ note } />) }
        </div>
    );
};

SurveyMessages.propTypes = {
    session: PropTypes.instanceOf(Session).isRequired,
};

export default SurveyMessages;

import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Field from './Field';
import QuestionModel from '../../Question';
import { addClassNames, parseDayTime, setDaytimeDate, formatDayTime, range } from '../../Utils';

import './TimePicker.scss';

/**
 * Simplified group of inputs to set a date time value in seconds
 * - exported as a small screen option for Daytime slider components
 * - @see https://en.wikipedia.org/wiki/12-hour_clock
 */

const PeriodSelect = function({ namespace, baseValue, handleChange, isClock, className }) {

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [initValue, setInitValue] = useState(baseValue);

    const hRange = range(0, (isClock) ? 23 : 11,  1);
    const mRange = range(0, 55, 5);

    const calculate = function() {
        // time
        const selected = (hours * 3600) + (minutes * 60);
        if (isClock) {
            return selected;
        }
        // period
        return initValue + selected;
    };

    useEffect(() => {
        const result = calculate();
        handleChange(result);
    }, [hours, minutes]);

    const update = function(value, scope) {
        switch (scope) {
            case 'hh':
                setHours(value);
            break;
            case 'mm':
                setMinutes(value);
            break;
        }
    };

    const renderHours = function(hh) {
        if (isClock) {
            const ham = hh % 24;
            return (ham % 12 || 12) + (ham < 12 ? ' am' : ' pm');
        }
        return `+ ${hh} hours`;
    };

    const renderMinutes = function(mm) {
        if (isClock) {
            return `${mm} min`;
        }
        return `+ ${mm} min`;
    };

    const cls = addClassNames('period-select', className);

    return (
        <div className={ cls } style={{ margin: '0 auto' }}>
            <select name={ `${namespace}-hours` } value={ hours }
                onChange={
                    (e) => {
                        const val = parseInt(e.target.value, 10);
                        update(val, 'hh');
                    }
                }
            >
            {
                hRange.map((val, index) => <option key={ index } value={ val }>{ renderHours(val) }</option>)
            }
            </select>
            <span> : </span>
            <select name={ `${namespace}-hours` } value={ minutes }
                onChange={
                    (e) => {
                        const val = parseInt(e.target.value, 10);
                        update(val, 'mm');
                    }
                }
            >
            {
                mRange.map((val, index) => <option key={ index } value={ val }>{ renderMinutes(val) }</option>)
            }
            </select>
            <div className="mt-2 text-center text-muted">{ formatDayTime(calculate()) }</div>
        </div>
    );

};

PeriodSelect.defaultProps = {
    isClock: false,

    className: '',
    handleChange: (seconds) => {},
};

PeriodSelect.propTypes = {
    baseValue: PropTypes.number.isRequired, // seconds
    isClock: PropTypes.bool, // render '+12 hours' as '12 pm'

    namespace: PropTypes.string.isRequired, // elem.id, elem.name,
    classname: PropTypes.string,

    handleChange: PropTypes.func,
};

/**
 * Group of inputs to set a date time value in seconds
 * - exported as a small screen option for Daytime slider components
 */

class DaytimeInput extends Component  {

    constructor(props) {
        super(props);
        this.state = {
            hours: 0,
            minutes: 0,
            ampm: 'am',
            errors: {},
        };
    }

    componentDidMount() {
        const parsed = parseDayTime(this.props.value);
        this.setState({
            hours: parsed.hours,
            minutes: parsed.mins,
            ampm: parsed.ampm,
            errors: {},
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            const parsed = parseDayTime(this.props.value);
            this.setState({
                hours: parsed.hours,
                minutes: parsed.mins,
                ampm: parsed.ampm,
                errors: {},
            });
        }
    }

    checkError(element, stateProp) {
        const { errors } = this.state;
        delete(errors[stateProp]);
        // @see https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
        if(element && typeof element.validity !== 'undefined') {
            if (!element.validity.valid) {
                errors[stateProp] = new Error(element.validationMessage);
                return errors;
            }
        }
        return errors;
    }

    handleChange(e) {
        e && e.preventDefault();
        const { handleSubmit } = this.props;
        const { errors, hours, minutes, ampm } = this.state;

        const hasErrors = Object.keys(errors).length !== 0;
        if (hasErrors) {
            return;
        }

        const date = setDaytimeDate(hours, minutes, ampm);
        let ts = date.getTime();
        ts  = (ts > 0) ? ts / 1000 : 0;
        console.log('---', ts, date);
        handleSubmit(ts);
    }

    render () {
        const { handleSubmit, namespace } = this.props;
        const { hours, minutes, ampm, errors } = this.state;

        const hasErrors = Object.keys(errors).length !== 0;

        return (
            <div className="row">
                <div className="col-md-3 mb-1">
                    <input
                        id={ `${namespace}--hours` }
                        name={ `${namespace}--hours` }
                        type="number"
                        placeholder="hours"
                        aria-label="hours"
                        className="form-control"
                        min={ 0 }
                        max={ 12 }
                        step={ 1 }
                        value={ hours }
                        onChange={ (e) => {
                            const { value } = e.target;
                            this.setState({
                                hours: parseInt(value, 10),
                                errors: this.checkError(e.target, 'hours'),
                            });
                            this.handleChange();
                        } }
                    />
                    { errors.hours && <div className="text-danger"><small>{ errors.hours.toString() }</small></div> }
                </div>

                <div className="col-md-3 mb-1">
                    <input
                        id={ `${namespace}--minutes` }
                        name={ `${namespace}--minutes` }
                        type="number"
                        placeholder="minutes"
                        aria-label="minutes"
                        className="form-control"
                        min={ 0 }
                        max={ 60 }
                        step={ 5 }
                        value={ minutes }
                        onChange={ (e) => {
                            const { value } = e.target;
                            this.setState({
                                minutes: parseInt(value, 10),
                                errors: this.checkError(e.target, 'minutes'),
                            });
                            this.handleChange();
                        } }
                    />
                    { errors.minutes && <div className="text-danger"><small>{ errors.minutes.toString() }</small></div> }
                </div>

                <div className="col-md-2 mb-1">
                    <select
                        id={ `${namespace}--minutes` }
                        name={ `${namespace}--minutes` }
                        value={ ampm }
                        className="form-control"
                        onChange={ (e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            this.setState({ ampm: value });
                            this.handleChange();
                        } }
                    >
                        <option value="am">am</option>
                        <option value="pm">pm</option>
                    </select>
                </div>

                <div className="col-md-4">
                    <button
                        className="btn btn-secondary"
                        disabled = { hasErrors }
                        onClick={ (e) => {
                            e.preventDefault();
                            const date = setDaytimeDate(hours, minutes, ampm);
                            const ts = date.getTime();
                            handleSubmit((ts > 0) ? ts / 1000 : 0);
                        } }
                    >OK</button>
                </div>
            </div>
        );
    }
};

DaytimeInput.defaultProps = {
    value: 0,
    minuteStep: 5,
};

DaytimeInput.propTypes = {
    value: PropTypes.number,
    namespace: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    minuteStep: PropTypes.number,
};

/**
 * Timepicker question component
 */

class TimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }

    componentDidMount() {
        const { question } = this.props;

        let val = Number(question.default_value);

        this.setState({
            value: (!isNaN(val)) ? val : 0,
        });
    }

    handleChange(value) {
        const { question, handleChange } = this.props;

        this.setState({
            value,
        });
        handleChange(null, question, value);
    }

    render() {
        const { value } = this.state;
        const { question, error, required, grouped, className, minuteStep } = this.props;

        return (
            <Field.Row className={ className } question={ question } grouped={ grouped } required={ required }>
                <Field.Description question={ question } grouped={ grouped } required={ required } />
                <Field.Title element="label" grouped={ grouped } question={ question } required={ required }>
                    <Field.Unit className="badge badge-secondary ml-1" question={ question } grouped={ grouped } />
                </Field.Title>

                <DaytimeInput
                    value={ value }
                    handleSubmit={ this.handleChange.bind(this) }
                    namespace={ question.id }
                    minuteStep={ minuteStep }
                />

                <Field.Error error={ error } grouped={ grouped } />
            </Field.Row>
        );
    }
};

TimePicker.defaultProps = {
    grouped: false,
    required: false,
    minuteStep: 5,
};

TimePicker.propTypes = {
    handleChange: PropTypes.func.isRequired,
    question: QuestionModel.propTypes().isRequired,
    error: PropTypes.instanceOf(Error),
    grouped: PropTypes.bool,
    required: PropTypes.bool,

    className: PropTypes.string,
    minuteStep: PropTypes.number,
};

export { TimePicker as default, DaytimeInput, PeriodSelect };

import React from 'react';
import PropTypes from 'prop-types';

import { Models,  normalizeAnalysis, analysisPropTypes } from '../../Analysis';
import Condition from './Condition';


const AnalysisBody = function({ analysis, className }) {
    const { conditions } = normalizeAnalysis(analysis);

    return (
        <div className={ className }>
            {
                conditions.map((condition, index) =>
                    <div key={ index }>
                        <span className="text-muted">Condition { index + 1 }/{ conditions.length }:</span>
                        <Condition prefix={ `${index + 1}/${conditions.length}` } condition={ condition }/>
                    </div>
                )
            }
        </div>
    );
}

AnalysisBody.defaultProps = {
    analysis: Models.analysis(),
    className: 'mb-4',
};

Condition.propTypes = {
    analysis: analysisPropTypes(),
    className: PropTypes.string,
};

export default AnalysisBody;

//Basic Header made with bootstrap
import React, { useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { AuthContext } from './AuthProvider';
import { DropdownMenu, MenuLink } from './bootstrap/DropdownMenu';

import logo from '../assets/logo.png';
import './Header.scss';

const {
    NODE_ENV,
    REACT_APP_SURVEY_PROVIDER,
    REACT_APP_SITE_NAME,
} = process.env;

const surveyProvider = REACT_APP_SURVEY_PROVIDER.trim();
const siteName = REACT_APP_SITE_NAME.trim();


// toggles navbar collapse
// element.classList support is ie > 10, we need this to work always!
const toggle = function(node, cls){
    if (!node) {
        return;
    }

    const rex = `(?:^|\\s)${cls}(?!\\S)`;

    let className = (node.className) ? node.className : '';
    if(className.match(new RegExp(rex))) {
        className = className.replace(new RegExp(rex, 'g') , '');
    } else {
        className += ` ${cls}`;
    }
    node.className = className;
};


const User = function() {
    const auth = useContext(AuthContext);

    if (!auth.is_protected) {
        return(null);
    }

    const to = (!auth.user) ? '/login' : '/user';

    return (
        <NavLink className="btn btn-success" to={ to }>
            <i className="fa fa-user"></i>
        </NavLink>
    );
};

const DemoMenu = function() {
    const buttonClass = (/^\/demo/.test(window.location.pathname)) ? 'btn btn-light' : 'btn btn-secondary';

    return (
        <DropdownMenu
            title="Demos"
            buttonClass={ buttonClass }
            alignmentClass="dropdown-menu-right"
        >
            <MenuLink to="/demo/form">Form Elements</MenuLink>
            <MenuLink to="/demo/analyse">Analysis</MenuLink>
            <MenuLink to="/demo/manifest">Manifest</MenuLink>
        </DropdownMenu>
    );
};

User.propTypes = {};

/**
 * Header navw ith simple hamburger, using inbuilt Bootstrap styles (but not js)
 * @see Header.scss for classnames
 */
const Header = function({ location }) {

    return (
        <header>
            <nav id="header-nav" className="navbar navbar-expand-md navbar-dark fixed-top bg-dark shadow-sm">
                <NavLink to="/" className="navbar-brand align-items-center"><img src={ logo } className="mr-4" title={ surveyProvider } alt="logo" height="30" />{ siteName }</NavLink>

                <button
                    id="navbar-toggler"
                    className="navbar-toggler collapsed"
                    type="button" data-toggle="collapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"
                    onClick={ (e) => {
                        e.preventDefault();
                        toggle(document.getElementById('navbar-toggler'), 'collapsed');
                        toggle(document.getElementById('header-nav--collapse'), 'show');
                    } }
                >
                    <span className="navbar-close"><strong>X</strong></span>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="header-nav--collapse">
                    <ul className="navbar-nav mr-auto" />

                    <ul className="navbar-nav mt-2 mt-md-0">
                        { (NODE_ENV !== 'production') && <li className="nav-item ml-3"><DemoMenu /></li> }
                        <li className="nav-item ml-3">
                            <User/>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

Header.propTypes = {};

export default withRouter(Header);

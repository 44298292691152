import React from 'react';

const Page404 = function(){
    return (
        <div>Sorry, this page doesn't exist !</div>
    );
};

Page404.propTypes = {};

export default Page404;

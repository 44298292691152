import React from 'react';
import PropTypes from 'prop-types';

import { conditionPropTypes } from '../../Analysis';

// import Insights from './Insights';
import Toggle from '../Toggle';
import InnerHtml from '../InnerHtml';

import { isArray } from '../../Utils';

const Item = function({ name, condition, html }) {
    const value = condition[name] || '';

    if(!value) {
        return (null);
    }

    return (
        <div className="list-group-item">
            <div className="row">
                <div className="col-sm-3">{ name }</div>
                {
                    (html) ? <InnerHtml className="col-sm-9" htmlContent={ value } /> : <div className="col-sm-9">{ value }</div>
                }
            </div>
        </div>
    );
};

Item.defaultProps = {
    html: false,
};

Item.propTypes = {
    name: PropTypes.string.isRequired,
    condition: conditionPropTypes(),

    html: PropTypes.bool,
};

const Details = function({ className, condition }) {
    // condition must have been normalized by a parent
    const { Tips, Recommendation } = condition;
    const LearnMore = condition['Learn more'] || '';

    let len = 0;
    len += LearnMore ? 1 : 0;
    len += Tips ? 1 : 0;
    len += Recommendation ? 1 : 0;

    if (!len) {
        return (null);
    }

    return (
        <div className={ className }>
        {
            (LearnMore || Tips) &&
            <Toggle title={ `More (${len})`} className="list-group mb-2" titleTag="div" titleClassName="text-primary ml-4 pb-2">
                <Item name="Recommendation" condition={ condition } html={ true }/>
                <Item name="Learn more" condition={ condition } html={ true }/>
            </Toggle>
        }
        </div>
    );
};

Details.defaultProps = {
    className: '',
    condition: null,
};

Details.propTypes = {
    className: PropTypes.string,
    condition: conditionPropTypes(),
};

/**
 * ss-django#207: load mandatoryTips fragments: changed from text to a fragment list
 *    -  parent: https://bitbucket.org/wall0159/sleepcompanionreimplementation/issues/109
 */
const Tips = function({ className, condition }) {
    // condition must have been normalized by a parent
    const { Tips } = condition;

    // legacy analyses (before Nov 2023) can be (html) strings
    if (!isArray(Tips)) {
        return (
            <Item name="Tips" condition={ condition } html={ true }/>
        );
    }

    if (!Tips.length) {
        return (null);
    }

    return (
        <div className={ className }>
            <div className="row">
                <div className="col-sm-3">Tips</div>
                <div className="col-sm-9">
                {
                    Tips.map((insight, index) => {
                        return (
                            <React.Fragment key={ index }>
                                <strong>{ insight[0] }</strong>: <InnerHtml htmlContent={ insight[1] } />
                            </ React.Fragment>
                        );
                    })
                }
                </div>
            </div>
        </div>
    );
};

Tips.defaultProps = {
    className: '',
    condition: null,
};

Tips.propTypes = {
    className: PropTypes.string,
    condition: conditionPropTypes(),
};

const Insights = function({ className, condition }) {

    // condition must have been normalized by a parent
    const { Insights } = condition;

    if (!Insights.length) {
        return (null);
    }

    return (
        <div className={ className }>
        {
            <Toggle title={ `Insights (${Insights.length})` } className="mb-2" titleTag="div" titleClassName="text-primary ml-4 pb-2">
                {
                    Insights.map((insight, index) => {
                        return (
                            <div key={ index } className="list-group-item">
                                <strong>{ insight[0] }</strong><br /><InnerHtml htmlContent={ insight[1] } />
                            </div>
                        );
                    })
                }
            </Toggle>
        }
        </div>
    );
};

Insights.defaultProps = {
    className: '',
    condition: null,
};

Insights.propTypes = {
    className: PropTypes.string,
    condition: conditionPropTypes(),
};

const Condition = function({ condition, prefix }) {

    // condition must have been normalized by a parent
    const { Description, Condition } = condition;

    return (
        <div className="mb-4">
            <h3>{ Condition }</h3>
            <div className="list-group mb-2">
                <Item
                    name="Category"
                    condition={ condition }
                />
                <Item
                    name="Classification"
                    condition={ condition }
                />
                <InnerHtml className="list-group-item" htmlContent={ Description } />
                <Tips className="list-group-item" condition={ condition } />
            </div>

            <Details condition={ condition } />
            <Insights condition={ condition } />

        </div>
    );
}

Condition.defaultProps = {
    condition: null,
};

Condition.propTypes = {
    condition: conditionPropTypes(),
};

export default Condition;

/**
 * @module APIhttp request API to surveysystem/backend
 */

import { buildUrl } from './Utils';
import JwtAuth from './JwtAuth';


const {
    REACT_APP_SURVEYAPI_ENDPOINT,
    REACT_APP_SURVEYAPI_HEADERS,
} = process.env;

const BaseHeaders = {};
try {
    const cHeaders = JSON.parse(REACT_APP_SURVEYAPI_HEADERS || '{}');
    Object.assign(BaseHeaders, cHeaders);
} catch(e) {
    // nothing
}


class ApiError extends Error {
    constructor(message, response = {}) {
        super(message);
        this.status = response.status || null;
        this.statusText = response.statusText || null;
        this.url = response.url || null;
    }
}

/**
 * Constructs an url, based on REACT_APP_SURVEYAPI_ENDPOINT
 *
 * @returns {string}
 */
const url = function(path, params) {
    return buildUrl(REACT_APP_SURVEYAPI_ENDPOINT, path, params);
};

/**
 * Fetch response content from a fetch response,
 * if response is a JSON  response then the content will be parsed (ss-frontend#27, ss-django#174)
 *
 * @returns {string}
 */
const json_or_text_response = function(response) {
    const cType = response.headers.get('content-type');
    if (cType && cType.indexOf('application/json') !== -1) {
        return response.json();
    }
    return response.text();
};

/**
 * Constructs default request headers
 * @returns {object}
 */
const requestHeaders = function(headers) {
    headers = headers || {};

    const requestHeaders = {
        'If-Match': localStorage.getItem('ss_consinstency_hash'),
    };
    const authHeaders = JwtAuth.request_headers();

    // add consistency header
    Object.assign(headers, BaseHeaders, requestHeaders, authHeaders);

    return headers;
};

/**
 * Stores response ETag in localStorage (#268)
 * @returns {object}
 */
const cacheResponse = function(response) {
    if (!response || !response.ok) {
        return;
    }
    const etag = response.headers.get('ETag');
    if (etag) {
        localStorage.setItem('ss_consinstency_hash', etag);
    }
};

/**
 * Handles and formats response errors in a promise context
 * @param {Response} fetch response object @see https://developer.mozilla.org/en-US/docs/Web/API/Response
 * @returns {Promise} Promise object throwing an exception (being catched in flow)
 */
const responseError = function (response) {
    return response.text()
        .then((text) => {
            throw new ApiError(text.replace(/(<([^>]+)>)/ig, ''), response);
        });
};

/**
 * set up AbortController
 * #441
 */
let controller = null;
let signal = null;
if (typeof window.AbortController !== 'undefined') {
    controller = new AbortController();
    signal = controller.signal;
}

/**
 * Backend requests
 * @type {object}
 */
const Api = {

    /**
     * cancel running xhr requests using fetch api's AbortController
     * #441
     *
     * @returns {void}
     */
    cancelRequests: function() {
        if (controller) {
            controller.abort();
        }
    },

    /**
     * Request a session
     * @returns {Promise}
     */
    getSession: function(session_id) {
        const uri = url('/session', {
            'session_id': session_id,
        });

        const opts =  {
            signal,
            headers: requestHeaders(),
        };

        return fetch(uri, opts)
            .then((response) => {
                if (!response.ok) {
                    return responseError(response);
                }
                cacheResponse(response);
                return response.json();
            })
            .catch((error) => {
                if(error && error.name === 'AbortError') {
                    return;
                }
                throw error;
            });
    },

    /**
     * Fetches next question(s)
     * @param {string} session_id
     *
     * @returns {Promise} deserialized json including next questions
     */
    nextQuestion: function(session_id) {
        const uri = url('/questions', {
            'session_id': session_id,
        });

        const opts =  {
            signal,
            headers: requestHeaders(),
        };

        return fetch(uri, opts)
            .then((response) => {
                if (!response.ok) {
                    return responseError(response);
                }
                cacheResponse(response);
                return response.json();
            })
            .catch((error) => {
                if(error && error.name === 'AbortError') {
                    return;
                }
                throw error;
            });
    },

    /**
     * Add answers for previous question id sand receive next question(s)
     * @param {string} session_id
     * @param {string} question_id
     * @returns {Promise} deserialized json including next questions
     */
    addAnswers: function(session_id, answers) {
        const uri = url('/answers');

        const opts =  {
            method: 'POST',
            headers: requestHeaders({
                'Content-Type': 'application/json',
            }),
            signal,
            body: JSON.stringify({
                session_id,
                answers,
            }),
        };

        return fetch(uri, opts)
            .then((response) => {
                if (!response.ok) {
                    return responseError(response);
                }
                cacheResponse(response);
                return response.json();
            })
            .catch((error) => {
                if(error && error.name === 'AbortError') {
                    return;
                }
                throw error;
            });
    },

    /**
     * Delete previous answers and receive next question(s) (#268)
     * Requies the last consistency checksum supplied by a next_questions response stored in localStorage
     * @param {string} session_id
     * @returns {Promise} deserialized json including next questions
     */
    deletePreviousAnswer: function(session_id) {
        const uri = url('/answers', {
            'session_id': session_id,
        });

        const opts =  {
            method: 'DELETE',
            signal,
            headers: requestHeaders(),
        };

        return fetch(uri, opts)
            .then((response) => {
                if (!response.ok) {
                    return responseError(response);
                }
                cacheResponse(response);
                return response.json();
            })
            .catch((error) => {
                if(error && error.name === 'AbortError') {
                    return;
                }
                throw error;
            });
    },

    // TODO: this is a temporary implementation

    /**
     * Mark survey as finished and receive evaluation
     * @param {string} session_id
     * @returns {Promise} deserialized json with evaluationdata
     */
    finishSurvey: function(session_id) {
        const uri = url('/analysis', {
            'session_id': session_id,
        });

        const opts =  {
            signal,
            headers: requestHeaders(),
        };

        return fetch(uri, opts)
            .then((response) => {
                if (!response.ok) {
                    return responseError(response);
                }
                cacheResponse(response);
                return response.json();
            })
            .catch((error) => {
                if(error && error.name === 'AbortError') {
                    return;
                }
                throw error;
            });
    },

    /**
     * Get survey info
     * @param {string} survey_id
     * @returns {Promise} deserialized json with evaluationdata
     */

    getSurvey: function(survey_id, page=null) {
        const params = {
            'survey_id': survey_id,
        };
        if (page) {
            params['page'] = page;
        }
        const uri = url('/survey', params);

        const opts =  {
            signal,
            headers: requestHeaders(),
        };

        return fetch(uri, opts)
            .then((response) => {
                if (!response.ok) {
                    return responseError(response);
                }

                cacheResponse(response);
                return json_or_text_response(response); // parsed json or text
            })
            .catch((error) => {
                if(error && error.name === 'AbortError') {
                    return;
                }
                throw error;
            });
    },

    /**
     * Generic endpoint request
     * @param {string} session_id
     * @returns {Promise} deserialized json with evaluationdata
     *
     * @see RoboSparrow/ss-django#237
     */
    genericRequest: function(path, params) {
        const uri = url(path, params || {});

        const opts =  {
            signal,
            headers: requestHeaders(),
        };

        return fetch(uri, opts)
            .then((response) => {
                if (!response.ok) {
                    return responseError(response);
                }
                cacheResponse(response);
                return response;
            })
            .catch((error) => {
                if(error && error.name === 'AbortError') {
                    return;
                }
                throw error;
            });
    },

};

Api.getAnalysis = Api.finishSurvey; //TODO tmp

export { Api as default, ApiError, responseError };

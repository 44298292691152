import React, { useRef, useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { AuthContext } from '../../Context';


const UserLogin = function() {
    const name = process.env.REACT_APP_SITE_NAME || 'Surveysystem';
    const usernameRef = useRef();
    const passwordRef = useRef();

    const context = useContext(AuthContext);

    const handleSubmit = (e) => {
        e.preventDefault();

        const username = usernameRef.current.value;
        const password = passwordRef.current.value;

        context.login(username, password);
    };

    return(
        <React.Fragment>
            { !context.user && <div className="alert alert-warning mb-2">Your session access has been expired</div> }

            <h2 className="card-title">Log into <small>{ name.trim() }</small></h2>
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <Link to="/login" className="nav-link active" href="/login" role="tab" aria-controls="login" aria-selected="true">Login with password</Link>
                </li>
                <li className="nav-item">
                    <Link to="/register" className="nav-link" role="tab" aria-controls="profile" aria-selected="false">Login with registration token</Link>
                </li>
            </ul>

            <div className="tab-content mt-2">
                <form onSubmit={ handleSubmit }>
                    <div className="form-group">
                        <label>Username: </label>
                        <input  className="form-control" ref={ usernameRef } type="text" />
                    </div>
                    <div className="form-group">
                        <label>Password: </label>
                        <input className="form-control" ref={ passwordRef } type="password" />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

UserLogin.propTypes = {};

const Login = function() {
    const context = useContext(AuthContext);

    if (!context.is_protected) {
         return (
            <Redirect to='/' />
         );
    }

    return(
        <React.Fragment>
            <h1>Account</h1>
            <div className="card">
                <div className="card-body">
                    <UserLogin />
                </div>
            </div>
        </React.Fragment>
    );
};

Login.propTypes = {};

export default Login;

import React, { useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Redirect, Link, useParams } from 'react-router-dom';

import { AuthContext } from '../../Context';

/**
 * if a token param was supplied
 */
const TokenRegister = function({ token }) {
    const name = process.env.REACT_APP_SITE_NAME || 'Surveysystem';

    const tokenRef = useRef();
    const context = useContext(AuthContext);

    const handleSubmit = (e) => {
        e.preventDefault();

        const token = tokenRef.current.value.trim();
        context.register(token);
    };

    return(
        <React.Fragment>
            { (!context.user && !token) && <div className="alert alert-warning mb-2">Your acess has been expired</div> }
            { token && <div className="alert alert-info mb-2">Please submit the registration token in order to get access to your survey</div> /* triggered only if token part of /registration/<token> url */}

            <h2 className="card-title">Register session for <small>{ name.trim() }</small></h2>
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                     <Link to="/login" className="nav-link" href="/login" role="tab" aria-controls="login" aria-selected="true">Login with password</Link>
                </li>
                <li className="nav-item">
                    <Link to="/register" className="nav-link active" role="tab" aria-controls="registration" aria-selected="false">Login with registration token</Link>
                </li>
            </ul>

            <div className="tab-content mt-2">
                <form onSubmit={ handleSubmit }>

                    <div className="form-group">
                        <label>Registration token: </label>
                        <input className="form-control" ref={ tokenRef } type="text" defaultValue={ token } />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

TokenRegister.defaultProps = {
    token: '',
};

TokenRegister.propTypes = {
    token: PropTypes.string,
};

const Register = function() {
    // extract token param from url
    const context = useContext(AuthContext);
    const { token } = useParams();

    if (!context.is_protected) {
         return (
            <Redirect to='/' />
         );
    }

    return(
        <React.Fragment>
            <h1>Account</h1>
            <div className="card">
                <div className="card-body">
                    <TokenRegister token={ token } />
                </div>
            </div>
        </React.Fragment>
    );
};

Register.propTypes = {};

export default Register;

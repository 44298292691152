import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Session from '../../Session';
import Api from '../../Api';

import SurveyInfo, { EmbeddedSurveyContentLink } from '../SurveyInfo';
import Modal from '../Modal';

const { PUBLIC_URL } = process.env;

class SurveyHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            survey: null,
        }
    }

    componentDidMount() {
        const { session } = this.props;
        Api.getSurvey(session.survey_id)
        .then(survey => this.setState({ survey }))
        .catch(error => { /* nothing */ });
    }

    render () {

        const { session } = this.props;
        const { survey } = this.state;

        return (
            <React.Fragment>
                <h1>{ (survey) ? survey.name : session.session_id }</h1>
                {
                    (survey) ?
                        <ul className="nav">
                            <li className="nav-item">
                                <Modal
                                    title="About this survey"
                                    buttonClassName="btn btn-link btn-sm mr-3"
                                    buttonText={ () => 'About this survey' }
                                >
                                    <SurveyInfo survey={ survey } withButtons={ false }/>
                                </Modal>
                                {
                                    survey.pages.map(
                                        (page, index) => <EmbeddedSurveyContentLink
                                            key={ index }
                                            survey_id={ survey.id }
                                            src={ page.src }
                                            title={ page.title }
                                        />
                                    )
                                }
                            </li>
                        </ul>
                    : null
                }
            </React.Fragment>
        );
    }
};

SurveyHeader.defaultProps = {};

SurveyHeader.propTypes = {
    session: PropTypes.instanceOf(Session).isRequired,
};

export default SurveyHeader;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Auth from '../JwtAuth';
import { survey_link_url } from './User';
import { AuthContext } from '../Context';


const AuthProvider = function({ children }) {
    const [loading, setLoading] = useState('');
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    const is_protected = Auth.is_protected();
    const history = useHistory();

    const is_registering = function() {
        // currently browsing on registration url?
        // Note: Using core Location: we cannot use react-router-dom useLocation from within a helper function
        const { pathname } = window.location;
        return (pathname && pathname.indexOf('/register') > -1);
    };

    const handleError = function(error) {
        setLoading('');
        setError(error);
        return error;
    };

    /**
     * Set user and redirect to survey pages
     * @param {object} session_obj a Session() like object, containing the properties { survey_id, session_id }
     */
    const handleUser = function(user, session_obj= null) {
        setUser(user);
        setLoading('');
        setError(null);

        // do not redirect when currently browsing on registration url
        if (!user && is_registering()) {
            return null;
        }

        if (!user) {
            history.push('/login');
            return user;
        }

        if (session_obj) {
            const uri = survey_link_url(session_obj);
            history.push(uri);
            return user;
        }

        history.push('/user');
        return user;
    };

    const init = function() {
        setLoading('initalizing...');
        setError(null);

        return Auth.init()
        .then(user => handleUser(user))
        .catch(error => handleError(error));
    };

    const login = function(username, password) {
        setLoading('Logging in...');
        setError(null);

        return Auth.login(username, password)
        .then(() => Auth.init())
        .then(user => handleUser(user))
        .catch(error => handleError(error));
    };

    const register = function(token) {
        // #29, ss-django#178: api response { access, refresh, survey_id, session_id }
        let session_obj = null;

        setLoading('Registering session...');
        setError(null);

        return Auth.register(token)
        .then((res) => {
            session_obj = res;
            return Auth.init();
        })
        .then(user => handleUser(user, session_obj))
        .catch(error => handleError(error));
    };

    const logout = function() {
        setLoading('Logging out...');
        setError(null);

        return Auth.logout()
        .then(() => handleUser(null))
        .catch(error => handleError(error));
    };

    const is_expired = function() {
        const token = Auth.get_token('access');
        if (!token) {
            return false;
        }
        return Auth.is_expired(token);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        init();
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    return (
        <AuthContext.Provider value={{
            // auth data
            is_protected,
            user,
            // request state
            loading,
            error,
            // api methods
            init,
            login,
            register,
            logout,

            is_expired,
            is_registering,
        }}>
            { children }
        </AuthContext.Provider>
    );
};


export {
    AuthContext,
    AuthProvider
};

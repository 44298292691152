import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import Session, { delete_cached_session } from '../Session';
import Survey from '../Survey';

import Modal from './Modal';
import EmbedHtmlFile from './EmbedHtmlFile';
import { endsWith } from '../Utils';

const { PUBLIC_URL } = process.env;

const formatDate = function(timestamp) {
    return (timestamp) ? new Date(timestamp).toLocaleString() : 'n/a';
};

const isModalContentLink = function(src) {
    const extensions = [ '.html', '.htm', '.tpl' ];

    for (let i = 0; i < extensions.length; i++) {
        if (endsWith(src, extensions[i])) {
            return true;
        }
    }
    return false;
};

const EmbeddedSurveyContentLink = function({ survey_id, src, title }) {

    const is_modal  = isModalContentLink(src);
    const className = 'btn btn-link btn-sm p-0';

    let href = src;

    if(is_modal) {
        href = `${PUBLIC_URL}/surveys/${survey_id}/${src}`;

        return (
            <Modal
                title={ title }
                buttonClassName={ className }
                buttonText={ () => title || '' }
            >
                <EmbedHtmlFile
                    title={ title }
                    src={ href }
                    showTitle={ false }
                    />
            </Modal>
        );
    }

    return (
        <a href={ href }
           target="_blank"
           rel="noreferrer"
           className={ className }
        >{ title } </a>
    )

};

EmbeddedSurveyContentLink.defaultProps = {};

EmbeddedSurveyContentLink.propTypes = {
    survey_id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

/**
 * Renders session action buttons for a survey
 */
const SurveyInfoButtons = function({ survey, session, isCurrent }) {
    return (
        <React.Fragment>
        {
                (session) ?
                    <React.Fragment>
                        <Link to={ `/survey/${session.survey_id}/${session.session_id}` } className="btn btn-lg btn-primary">Continue Survey</Link>
                        <Link to={ `/survey/${session.survey_id}/new` } onClick={ (e) => {
                            // e.preventDefault(); intentionally commented out - do not break event probagation here
                            delete_cached_session();
                        } } className="btn btn-sm btn-link">Restart Survey</Link>
                    </React.Fragment>
                :
                    <Link to={ `/survey/${survey.id}` } className="btn btn-lg btn-primary">Start Survey</Link>
        }
        </React.Fragment>
    );
}

SurveyInfoButtons.defaultProps = {
    session: null,
    isCurrent: false,
};

SurveyInfoButtons.propTypes = {
    survey: Survey.propTypes().isRequired,
    session: PropTypes.instanceOf(Session),
    isCurrent: PropTypes.bool,
};

/**
 * Renders a single survey item
 */

const SurveyInfo = function({ survey, session, isCurrent, withButtons }) {

    if (survey.error) {
        return (
            <React.Fragment>
                <h3>{ (survey.name) ?  survey.name : survey.id }</h3>
                <div className="alert alert-danger" role="alert">{ survey.error.toString() }</div>
            </React.Fragment>
        );
    }

    const created = (session && typeof session.created !== 'undefined') ? session.created : 0;
    const modified = (session && typeof session.modified !== 'undefined') ? session.modified : 0;
    const session_id = (session && typeof session.session_id !== 'undefined') ? session.session_id : '';

    return (
        <React.Fragment>
            <h3>{ (survey.name) ?  survey.name : survey.id }</h3>
            { (survey.title) ?  <div className="mb-3"><strong>{ survey.title }</strong></div> : null }
            { (survey.description) ?  <div className="mb-3">{ survey.description }</div> : null }
            { (survey.organisation) ?  <div className="mb-3">Organisation: { survey.organisation }</div> : null }
            <div className="mb-3">
                <ul className="list-unstyled pl-2">

                    { (survey.email) ?  <li><i className="fas fa-envelope ml-2 mr-2"></i><a href={ `mailto: ${survey.email}?subject=Enquiry about survey ${survey.id}`} >{ survey.email }</a></li> : null }
                    { (survey.phone) ?  <li><i className="fas fa-phone ml-2 mr-2"></i>{ survey.phone }</li> : null }
                    {
                        survey.pages.map(
                            (page, index) =>
                                <li key={ index }>
                                    <i className="fas fa-external-link-alt ml-2 mr-2"></i>
                                    <EmbeddedSurveyContentLink key={ index } survey_id={ survey.id } src={ page.src } title={ page.title } />
                                </li>
                        )
                    }
                </ul>
            </div>
            <table className="mb-3" style={ { fontSize: '.8em' } }>
                <tbody>
                    { (session_id) ?  <tr><td className="pr-3">session_id:</td><td>{ session_id }</td></tr> : null }
                    { (created) ?  <tr><td className="pr-3">started:</td><td>{ formatDate(created) }</td></tr> : null }
                    { (modified) ?  <tr><td className="pr-3">last access:</td><td>{ formatDate(modified) }</td></tr> : null }
                </tbody>
            </table>
            {
                (withButtons) ?
                    <div className="mb-3">
                        <SurveyInfoButtons survey={ survey } session={ session } />
                    </div>
                : null
            }
        </React.Fragment>
    );
};

SurveyInfo.defaultProps = {
    session: null,
    isCurrent: false,
    withButtons: true,
};

SurveyInfo.propTypes = {
    survey: Survey.propTypes().isRequired,
    session: PropTypes.instanceOf(Session),
    isCurrent: PropTypes.bool,
    withButtons: PropTypes.bool,
};

export { SurveyInfo as default, EmbeddedSurveyContentLink };

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { AuthContext } from '../Context';
import Auth from '../JwtAuth';

import {
    get_session_state_name,

    SESSION_OPEN,
    SESSION_FINISHED,
} from '../Session';


const survey_link_url = function(item) {
    const { session_id, survey_id } = item;
    return `/survey/${survey_id}/${session_id}`;
};

const survey_link_text = function(session_state) {
    if (session_state < SESSION_OPEN)  {
        return 'Start';
    }
    if (session_state < SESSION_FINISHED)  {
        return 'Continue';
    }
    return 'Analyse';
};

const UserSessions = function({ sessions }) {
    return (
        <React.Fragment>
            <ul className="list-group">
            {
                sessions.map(session =>
                    <li key={ session.session_id } className="list-group-item">
                        <strong>Survey</strong>: { session.survey_id }< br />
                        <strong>Session</strong>: { session.session_id } <br />
                        <strong>State</strong>: { get_session_state_name(session.session_state) } <br />
                        <Link to={ survey_link_url(session) } className="btn btn-primary btn-xs mt-2">{ survey_link_text(session.session_state) }</Link>
                    </li>
                )
            }
            </ul>
        </React.Fragment>
    );
};

UserSessions.defaultProps = {
    sessions: [],
};

UserSessions.propTypes = {
    sessions: PropTypes.arrayOf(
        PropTypes.shape({
            session_id: PropTypes.string.isRequired,
            survey_id: PropTypes.string.isRequired,
        })
   ),
};


const TokenInfo = function() {
    const token = Auth.get_parsed_token('access');
    if (!token) {
        return(null);
    }

    const expires = token.exp || 0;
    if (!expires) {
        return(null);
    }
    const dt = new Date(expires * 1000);

    return (
        <span>Login expires: { dt.toLocaleDateString() } { dt.toLocaleTimeString() }</span>
    );
}

TokenInfo.propTypes = {};


const User = function() {
    const context = useContext(AuthContext);

    const user = context.user || {};
    const username = user.username || '<UNKNOWN>';
    const sessions  = user.sessions || [];

    return(
        <React.Fragment>
            <h2 className="card-title">
                Hello <span className="text-info text-capitalize">{ username }</span>
            </h2>
            <div className="row">
                <div className="col">
                    <TokenInfo />
                </div>
                <div className="col text-right">
                    <button onClick={
                        (e) => {
                            e.preventDefault();
                            context.logout();
                        }
                    } className="btn btn-link"><i className="fa fa-user mr-2"></i>Logout</button>
                </div>
            </div>
            <div className="mb-1"></div>
            <h3>Your Sessions:</h3>
            <UserSessions sessions={ sessions } />
        </React.Fragment>
    );
};

User.propTypes = {};

export  { User as default, survey_link_url };

import React from 'react';
import PropTypes from 'prop-types';

import Api from '../../Api';
import { Models,  normalizeAnalysis, analysisPropTypes } from '../../Analysis';

/**
 * Handle protected analysis pdf download (jwt)
 * @see RoboSparrow/ss-django#237
 */
const download_pdf = function(analysis) {

    const { session_id, mode } = normalizeAnalysis(analysis);
    const params = {
        session_id,
        mode,
        pdf: '1',
    }

    return Api.genericRequest('/analysis', params)
        .then(response => response.blob())
        .then(rawData => {
            const objectUrl = window.URL.createObjectURL(rawData);

            const anchor = document.createElement('a');
            anchor.id ='temporary-download-link'; // debug

            anchor.href = objectUrl;
            anchor.download = `analysis.${session_id}.pdf`;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
            document.body.removeChild(anchor);
        });
}

const AnalysisDownload = function({ analysis, className }) {
    return (
        <div className={ className }>
            <button onClick={ () => download_pdf(analysis) }  className="btn btn-sm btn-primary">Download as PDF</button>
        </div>
    );
};

AnalysisDownload.defaultProps = {
    analysis: Models.analysis(),
    className: 'mb-4',
};

AnalysisDownload.propTypes = {
    analysis: analysisPropTypes(),
    className: PropTypes.string,
};

export default AnalysisDownload;
